/**
* Template Name: FlexStart - v1.9.0
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  /* colores generados por https://colors.dopely.top/color-blender/2196f3-4caf50-5 */
  scroll-behavior: smooth;
  /*
  (no quedaba bien, ahora son todos iguales)
  --color-precio-1: #90CAF9;
  --color-precio-2: #93CCEB;
  --color-precio-3: #91D2E3;
  --color-precio-4: #92DBD9;
  --color-precio-5: #99D9C5;
  --color-precio-6: #9ED8B4;
  --color-precio-7: #A5D7A6;
  */
  --color-precio-1: #90CAF9;
  --color-precio-2: #90CAF9;
  --color-precio-3: #90CAF9;
  --color-precio-4: #90CAF9;
  --color-precio-5: #90CAF9;
  --color-precio-6: #90CAF9;
  --color-precio-7: #90CAF9;
}

body {
  font-family: "Open Sans", var(--bs-body-font-family), "sans-serif";
  background-image: url(../img/hero-bg.png);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* mantener el padding-top del body, sincronizado con el height del header del menu (tiene position: fixed) */
  padding-top: 72px;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito", var(--bs-body-font-family), "sans-serif"
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section .section-header {
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

section .section-header h2 {
  color: #012970;
  font-family: "Nunito", var(--bs-body-font-family), sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header .navbar {
  /* mantener el padding-top del body, sincronizado con el height de este header (tiene position: fixed) */
  height: 72px;
  opacity: 95%;
}
#header .navbar .navbar-nav .nav-link,
#header .navbar .navbar-nav .nav-link:hover {
  color: #012970;
}
#header .navbar .navbar-brand {
  color: #012970;
  font-family: "Nunito", var(--bs-body-font-family), sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero h2 {
  color: #012970;
  font-weight: 700;
}
.hero h3 {
  color: #444444;
  font-weight: 700;
}
.hero .hero-img {
  filter: invert(28%) sepia(100%) hue-rotate(-180deg) saturate(3) brightness(120%);
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  color: #4154f1;
  text-transform: uppercase;
}
.about .content h2 {
  color: #012970;
}
.about .btn {
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}
.about .btn span {
  font-family: "Nunito", var(--bs-body-font-family), sans-serif;
  letter-spacing: 1px;
}

.forced-max-height img {
  margin-left: auto;
  margin-right: auto;
  max-width: inherit;
}

/*--------------------------------------------------------------
# Other
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-icons .icon-box {
  width: 36ex;
}
.features .feature-icons .icon-box h4 {
  font-weight: 700;
  color: #012970;
}
.features .feature-icons .icon-box i {
  font-size: 48px;
  color: #0245bc;
}
.features .card {
  width:  28ex;
  margin: 2ex;
  background-color: #f6f9ff;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
}
.features .card:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transform: scale(1.08);
}
.features .card .text-muted {
  font-size: 85%;
}
.features .card-title {
  color: #012970;
  font-weight: 700;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .card {
  padding: 40px 20px;
  position: relative;
  overflow: hidden;
}
.pricing .price {
  color: #444444;
  font-family: "Poppins", var(--bs-body-font-family), sans-serif;
  font-weight: 600;
}
.pricing .price sup {
  font-size: 75%;
  top: -0.5ex;
  left: -3px;
}
.pricing .price span {
  font-size: 90%;
}
.pricing .price-each {
  font-size: 95%;
}
/*
.pricing .btn-buy {
  font-family: "Nunito", var(--bs-body-font-family), sans-serif;
  font-weight: 600;
  border: 1px solid #4154f1;
  color: #4154f1;
  margin-top: 3ex;
}
.pricing .btn-buy:hover {
  background: #4154f1;
  color: #fff;
}
*/
.pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -65px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  padding: 1px 0 3px 0;
  background: #4154f1;
  color: #fff;
  text-align: center;
}
.pricing .discount {
  width: 200px;
  position: absolute;
  top: 18px;
  left: -65px;
  transform: rotate(-45deg);
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  padding: 1px 0 3px 0;
  background: #ff0071;
  color: #fff;
  text-align: center;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
/* Titulos del footer */

footer a,
footer li.h4 {
  color: #012970;
}
footer ul li, footer *.h3 {
  margin-bottom: 0.5em;
}
footer *.h3 {
  border-bottom: thin solid #012970;
  padding-bottom: 0.2em;
}
